import { KeyboardService } from "../../../app/web/services/keyboard.service";
import { Injectable } from "../reflection/injectable";

@Injectable({ type: 'singleton' })
export class ModalService {

    public constructor(private _keyboardService: KeyboardService) {        
    }

    public open(element: HTMLElement, mode: 'modal' | 'popup', options?: { showMode: 'default' | 'height', disableKeyboard?: boolean }): void {
        const targetOptions: { showMode: 'default' | 'height', disableKeyboard?: boolean } = {
            showMode: 'default',
            disableKeyboard: true,
            ...options || {},
        };
        const { showMode } = targetOptions;
        if (showMode != 'default') {
            element.classList.add(...(mode == 'modal' ? ['u-modal', `u-modal-show-${showMode}`]: ['u-popup', `u-popup-show-${showMode}` ]));
        }
        else {
            element.classList.add(...(mode == 'modal' ? ['u-modal', 'u-modal-show']: ['u-popup', 'u-popup-show']));
        }
        if (mode == 'modal') {
            const bodyOverflowStyle: HTMLStyleElement = document.createElement('style');
            bodyOverflowStyle.id = 'body-overflow-style';
            bodyOverflowStyle.innerText = `html { overflow-y: hidden; }`;
            element.appendChild(bodyOverflowStyle);
        }
        if (targetOptions.disableKeyboard) {
            this._keyboardService.disableKeyboardTab(element);
        }
        element.setAttribute("aria-modal", "true");
    }

    public close(element: HTMLElement, options?: { showMode: 'default' | 'height' }): void {
        this._keyboardService.enableKeyboardTab();
        const targetOptions: { showMode: 'default' | 'height' } = {
            showMode: 'default',
            ...options || {},         
        };
        const { showMode } = targetOptions;        
        const mode: 'modal' | 'popup' = element.classList.contains('u-modal') ? 'modal': 'popup';
        if (showMode != 'default') {
            element.classList.remove(...(mode == 'modal' ? ['u-modal', `u-modal-show-${showMode}`]: ['u-popup', `u-popup-show-${showMode}`]));
        }
        else {
            element.classList.remove(...(mode == 'modal' ? ['u-modal', 'u-modal-show']: ['u-popup', 'u-popup-show']));
        }
        if (mode == 'modal') {
            const bodyOverflowStyle: HTMLStyleElement = document.getElementById("body-overflow-style") as HTMLStyleElement;
            if (bodyOverflowStyle && bodyOverflowStyle.parentNode) {
                bodyOverflowStyle.parentNode.removeChild(bodyOverflowStyle);
            }
        }
        element.setAttribute("aria-modal", "false");
    }
}