import { Injector } from "../reflection/injector";
import { ModalService } from "../services/modal.service";
import { ComponentBase } from "./component-base";

export class PopupComponent<TElement extends HTMLElement> extends ComponentBase<TElement> {

    private _modalService: ModalService = null;
    private _isOpened: boolean = false;
    private _promise: Promise<any> = null;
    private _resolve: (data?: any) => void = null;
    private _options: { showMode: 'default' | 'height' } = null;

    public constructor(node: TElement) {        
        super(node);

        this._modalService = Injector.get(ModalService);

        ['scroll', 'resize'].forEach(e => {
            this.addWindowEventListener(e, () => {
                if (!this._node.classList.contains('u-popup--fixed')) {
                    this.close()
                }
            });
        });
        this.addWindowEventListener('click', e => {
            if (this.isOpened && !(e.target as HTMLElement).closest('.u-popup')) {
                this.close();
            }
        });
        this.addCustomWindowEventListener('close-popups', () => this.close());
    }

    public onInit(): void {
        this.addEventListener('click', (e: MouseEvent) => e.stopPropagation(), '.u-popup');
    }

    public async toggle(data?: any, e?: MouseEvent): Promise<any> {
        if (!this.isOpened) {
            return await this.open(data, e);
        }
        else {
            this.close();
        }
    }
  
    public async open(data?: any, e?: MouseEvent): Promise<any> {
        this.dispatchCustomWindowEvent('close-popups');
        if (e) {
            e.stopImmediatePropagation();
        }
        this._promise = new Promise<any>((resolve: (data?: any) => void) => {
            this._resolve = resolve;
        });
        this._modalService.open(this.node, 'popup', {...this._options, disableKeyboard: false});
        this._isOpened = true;
        return this._promise;       
    }

    public close(data?: any): void {
        if (this._isOpened) {
            this._modalService.close(this.node, this._options);
            this._isOpened = false;
            this._resolve(data);
            this._resolve = null;
            this._promise = null;    
        }
    }

    public get isOpened(): boolean {
        return this._isOpened;
    }

    public set options(value: { showMode: 'default' | 'height' }) {
        this._options = value
    }
}